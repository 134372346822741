.main-header {
    @include theme-aware('background', 'header-background');
    color: white;
    flex-grow: 1;
    min-height: 64px;

    &--logo {
        text-align: center;

        @media (min-width: $sm) {
            text-align: unset;
        }
    }
}