body {
    @include theme-aware('background', 'color-background');
    @include theme-aware('color', 'color');
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    @include theme-aware('-webkit-text-fill-color', 'color');
}

.align-self--center {
    align-self: center;
}

.text-align-center {
    text-align: center;
}

.vertical-align-middle {
    vertical-align: middle;
}

.color-theme {
    @include theme-aware('color', 'color');
}

.display-text {
    white-space: pre-wrap;
}

table, td, th {
    border: 1px solid;
    @include theme-aware('border-color', 'input-border');
}

table {
    border-collapse: collapse;

    th {
        @include theme-aware('background-color', 'color-highlight');
    }

    td {
        padding: 0 5px;
    }
}