.ocs-layout {

  .inactive-mail{
    @include theme-aware('color', 'button-disabled-icon');
  }

  .scroll{
    max-height: 500px;
    overflow-y: auto;
  }

}