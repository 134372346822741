.footer {
    @include theme-aware('background', 'header-background');
    color: #ffffff;
    font-size: 12px;
    padding: 10px;
    text-align: center;

    a {
        color: #ffffff;
    }
}