.main-content {
    .MuiPaper-root {
        padding: 20px;
    }

    .MuiPaper-rounded {
        border-radius: 25px;
    }

    .main-paper {
        @include theme-aware('background', 'main');
    }

    .secondary-paper{
        @include theme-aware('background-color', 'secondary-paper');
        @include theme-aware('color', 'color');
    }
}