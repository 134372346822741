.main-content {
    flex-grow: 1;
    height: calc(100vh - 214px);
    overflow: auto;
    position: relative;
    margin-top: 162px;

    @media (min-width: $sm) {
        margin-top: 84px;
        height: calc(100vh - 120px);
    }

    .container--absolute-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 100%;
    }
}